const keys = {}

export function addKeyboardKey (e) {
  keys[e.key] = true
}

export function removeKeyboardKey (e) {
  delete keys[e.key]
}

export function isCtrlPress(e) {
  // eslint-disable-next-line
  return keys['Control']
}
