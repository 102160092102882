const middleware = {}

middleware['auth'] = require('..\\middleware\\auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['checkTimestamp'] = require('..\\middleware\\checkTimestamp.js')
middleware['checkTimestamp'] = middleware['checkTimestamp'].default || middleware['checkTimestamp']

middleware['redirect'] = require('..\\middleware\\redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
