
// 仅带一个logo的轻量级页首组件
export default {
  name: 'PcHeaderLite',
  methods: {
    toHome() {
      location.href = location.origin
    },
  },
}
