import { isCtrlPress } from '@/utils/keyboard'

export default ({ app, store, redirect, $cookies }) => {
  app.router.beforeEach((to, from, next) => {
    // 在每次路由跳转的时候进行判断
    if (isCtrlPress()) {
      // 若是 ctrl 按键被按下，则以新窗口打开目标页面
      window.open(to.fullPath)
      // 并阻止当前页面的跳转
      return next(false)
    }
    const token = $cookies.get("token");
    if (token) {
      store.commit("setUserInfo", $cookies.get("user-info"));
    } else {
      store.commit("clearUserInfo");
    }
    next();
  })
}
