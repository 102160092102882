export default ({ app }) => {
  if (process.client) {
    // 只在生产环境加载百度统计脚本
    const script = document.createElement('script');
    script.src = 'https://hm.baidu.com/hm.js?e1b6873b68a812cb909ea1f38dea478a';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }
}
